// Shadows
$drop-shadow: 	drop-shadow(0px 5px 10px rgba(#000, 0.1)) 
				drop-shadow(0px 1px 1px rgba(#000, 0.2)
                );
                

// App Title, Price, Description and Links

.app-info {
	display: flex;
	flex-wrap: wrap;
	padding-top: 50px;
    padding-bottom: 30px;
    align-content: flex-start;
}

@media only screen and (max-width: 992px) {

	.app-info {
		padding-top: 50px;
		justify-content: center;
	}

}

.app-icon-shadow {
	display: flex;
	filter: $drop-shadow;
}

.app-icon-large {
	width: 120px;
	height: 120px;
	-webkit-clip-path: url(#shape120);
	clip-path: url(#shape120);
}

.app-name-price-container {
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	margin-left: 30px;
}

.app-name {
    font-size: 2.5rem;
	font-weight: bolder;
    margin-bottom: 0px;
}

.app-price {
	// color: $app-price-color;
    font-size: 1.5rem;
	font-weight: normal;
	margin-top: 0px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {

	.app-name-price-container {
		width: 100%;
		margin-left: 0px;
		align-items: center;
		justify-content: center;
	}

	.app-name {
		margin-top: 15px;
		text-align: center;
	}
	
	.app-price {
		text-align: center;
	}
	
}

.app-download-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	margin-left: 30px;
}

.app-download-box {
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	align-items: center;
}

.app-store {
	height: 75px;
}

.app-presskit-link {
    display: flex;
}

@media only screen and (max-width: 768px) {

	.app-download-container {
        width: 100%;
        margin-top: 50px;
		margin-left: 0px;
		align-items: center;
		justify-content: center;
	}
	
}

// Feature List

.features {
	display: flex;
	flex: 0 1 auto;
	align-content: flex-start;
	justify-content: flex-start;
	flex-grow: 1;
	flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 50px;
}

.feature {
	display: flex;
	padding-top: 35px;
	padding-left: 15px;
	padding-right: 15px;
	width: calc(90%/3);
}

.feature:nth-child(-n+3) {
    padding-top: 0px;   
}

.feature:nth-child(3n) {
	padding-right: 0px;
}

.feature:nth-child(3n+1) {
	padding-left: 0px;
}

.icon-back {
	color:  $feature-icons-background-color;
}

.icon-top {
	color: $feature-icons-foreground-color;
}

@media (prefers-color-scheme: dark) {
	.icon-back {
		color:  $feature-icons-background-color-dark;
	}
	
	.icon-top {
		color: $feature-icons-foreground-color-dark;
	}
}

.feature-text {
	margin-left: 5px;
}

.feature-text > h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.feature-text > p {
	color: $feature-text-color;
	margin-top: 0px;
	line-height: 1.5;
}

@media only screen and (max-width: 768px) {

	.features {
		flex-grow: 1;
		flex-direction: row;
		flex-wrap: wrap;
		// margin-top: 11px;
	}

	.feature {
		display: flex;
		padding-top: 41px;
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}

	.feature:nth-child(-n+3) {
		padding-top: 41px;   
	}

	.feature:nth-child(1) {
		padding-top: 0px;   
	}

	.feature:nth-child(3n) {
		padding-right: 15px;
	}
	
	.feature:nth-child(3n+1) {
		padding-left: 15px;
	}
	
}

@media only screen and (max-width: 375px) {

	.features {
		flex-grow: 1;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 11px;
	}

	.feature {
		display: flex;
		padding-top: 41px;
		padding-left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.feature:nth-child(-n+3) {
		padding-top: 41px;   
	}

	.feature:nth-child(1) {
		padding-top: 0px;   
	}

	.feature:nth-child(3n) {
		padding-right: 0px;
	}
	
	.feature:nth-child(3n+1) {
		padding-left: 0px;
	}
	
} 
