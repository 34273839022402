/* ==========================================================================
   Fluid Type
   as seen on https://madebymike.com.au/writing/fluid-type-calc-examples/
   ========================================================================== */

  @use "sass:math";

  @function strip-unit($value) {
    @if unitless($value) {
      @return $value;
    } @else {
      @return math.div($value, $value * 0 + 1);
    }
  }
  
  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    // scss-lint:disable UnnecessaryParentReference
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);
  
    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
      & {
  
        font-size: $min-font-size;
  
        @media screen and (min-width: $min-vw) {
          font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
  
        @media screen and (min-width: $max-vw) {
          font-size: $max-font-size;
        }
      }
    } @else {
      @error "Detected mixed units. Please use the same units for all parameters. " + $u1 + ", " + $u2 + ", " + $u3 + ", " + $u4;
    }
  }